:root {
  --blue-logo-color: #1918df;
  --green-logo-color: #64bd05;
  --primary-text-color: #000;
  --background-color: #fffefe;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: Roboto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-color);
  background-color: var(--background-color);
  scroll-behavior: smooth !important;
}
html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}
.primary-text {
  color: var(--primary-text-color);
}

.blue-text {
  color: var(--blue-logo-color);
}
.green-text {
  color: var(--green-logo-color);
}
.green-text-hover {
  transition: 0.3s;
  color: var(--green-logo-color);
}
.green-text-hover:hover {
  transition: 0.3s;
  color: var(--blue-logo-color);
}
.blue-text-hover {
  transition: 0.3s;
  color: var(--blue-logo-color);
}
.blue-text-hover:hover {
  transition: 0.3s;
  color: var(--green-logo-color);
}

.green-bg-button {
  background-color: var(--green-logo-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  transition: 0.6s;
}
.green-bg-button:hover {
  background-color: var(--blue-logo-color);
  color: var(--background-color);
  transition: 0.6s;
}
.blue-bg-button:hover {
  background-color: var(--green-logo-color);
  color: var(--background-color);
  transition: 0.6s;
}
.blue-bg-button {
  transition: 0.6s;
  background-color: var(--blue-logo-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
}
.skew-button {
  transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.skew-button:hover {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.4);
}

.white-text {
  color: var(--background-color);
}

a {
  text-decoration: none;
  color: var(--primary-background-color);
}

.section-margin {
  margin-top: 4rem;
}

.green-bg {
  background-color: #64bd05be;
}
.blue-bg {
  background-color: #1818dfbe;
}

.slick-next::before {
  color: var(--green-logo-color) !important;
  font-size: 2rem !important;
  margin-left: 5px;
}
.slick-prev::before {
  color: var(--green-logo-color) !important;
  font-size: 2rem !important;
  margin-left: -25px;
}

.heading {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.wrapper-container {
  width: 90%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }
}

.main-heading:hover {
  transition: 0.5s;
  scale: 1.01;
}
.main-heading {
  transition: 0.5s;
  scale: 1;
}
