.privacyPolicyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .introductionContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 1rem;
    transition: 0.3s ease-in-out;
  }
  .introductionContainer:hover {
    transition: 0.3s ease-in-out;
    box-shadow: 5px 12px 20px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .introduction {
    font-size: 1rem;
    font-weight: 500;
    /* width: 50%; */
    letter-spacing: 0.02em;
  }
  
  .introductionContainer > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin: 0 2rem;
  }
  .introOfMitul {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 500px; */
  }
  .introOfMitul:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .container::after,
  .row::after {
    content: '';
    clear: both;
    display: table;
  }
  
  .featureContainer {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 30px;
  }
  
  .header {
    margin-bottom: 30px;
    /* max-width: 800px; */
    text-align: center;
  }
  
  .header p {
    font-size: 18px;
    color: #666;
  
    text-align: center;
  }
  
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
  }
  
  .feature {
    width: 300px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .feature:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .feature h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .color{
    color:blue
  }
  
  @media screen and (max-width: 1000px) {
    .introductionContainer {
      flex-direction: column;
    }
    .introduction {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .introOfMitul {
      width: 100%;
    }
  }
  