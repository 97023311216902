.MainContainer {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1400px;
  min-width: 320px;
  margin: 0 auto;
}
