.footer {
  width: 100%;
  background-color: rgba(240, 248, 254);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
}

.footer_container {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* margin-left: 20px; */
  flex-wrap: wrap;
  width: 90%;

  margin: auto;
}
.footer_container > img {
  border-radius: 1rem;
}
.footerTopRight {
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  height: 100px;
}

.Address {
  cursor: pointer;
}
.Address > a {
  font-size: 1.2rem;
}

.footerBottom {
  background-color: rgba(28, 28, 41, 255);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
  margin-top: 20px;
}
.footercheck {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: auto;
}

.Text {
  font-size: 1.5rem;
  font-weight: 500;
}
.footerRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}
.footerRight img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
}
