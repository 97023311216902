.container {
  width: 90%;
  max-width: 600px;
  text-align: center;
  /* overflow: hidden; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.heading {
  font-size: 3rem;

  margin-bottom: 1rem;
}

.containerItem {
  display: flex;
  gap: 1rem;
  min-height: 300px;
}

.shadowEffect {
  background: #fff;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ececec;
  padding: 2rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}
.image {
  transform-style: preserve-3d;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
}
.description {
  font-size: 17px;
  line-height: 1.5;
  font-weight: 300;
  width: 90%;
  margin-top: 1rem;
}
.name {
  display: table;
  padding: 9px 35px;
  border-radius: 12px;
  margin: auto;
  margin-top: 1rem;
  text-align: center;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}
