.spacing > * + * {
  margin-top: var(--spacer, 2rem);
}
.container {
  width: 100%;

  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.primary-title {
  font-size: 3rem;
  color: rgb(216, 216, 216);
  /* font-size: clamp(3rem, calc(5vw + 1rem), 4.5rem); responsive font */
  line-height: 1;
  text-transform: uppercase;
}

.section-title {
  text-align: center;
  font-size: 3.5rem;
  font-size: clamp(2.5rem, calc(5vw + 1rem), 4rem);
  line-height: 1;
  color: #17353d;
  /* margin-bottom: 5rem; */
}
.heroImage {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  gap: 1rem;
}

.leftImage,
.rightImage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  gap: 1rem;
}
.imgContainer {
  position: relative;
}
.leftImage > .imgContainer,
.rightImage > .imgContainer {
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  /* height: 400px; */
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.imgContainer > img {
  border-radius: 1rem;
  transition: all 0.3s;
  border: 2px solid #1918df;
}
.imgContainer:hover {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.4);
  scale: 1.01;
  cursor: pointer;
}

.heroImage > img {
  max-width: 400px;
  width: 30%;
  /* height: auto; */
  text-align: center;

  /* background: green; fallback if blend mode is not supported */
  transition: 0.3s;
  border-radius: 1rem;
  padding: 1rem 3rem;
  border: 2px solid #1918df;
}
.heroImage > img:hover,
.heroImage > div > img:hover {
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.4);
  scale: 1.01;
  cursor: pointer;
}
.hiddenContent {
  display: none;
  position: absolute;
  /* top: 100%; */

  /* background-color: rgba(240, 248, 254); */
  padding: 10px;
  width: 250px;
  height: 250px;
  border-radius: 1rem;
  transition: all 0.3s;
}
.hoverDiv:hover .hiddenContent {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background-color: rgba(240, 248, 254);
}

@media screen and (max-width: 1200px) {
  .heroImage {
    flex-direction: column;
  }
  .heroImage > img {
    width: 100%;

    max-width: 500px;
    min-width: 300px;
  }
  .leftImage,
  .rightImage {
    justify-content: center;
  }
}
/* @media screen and (max-width: 600px) {
  .heroImage > img {
    width: 400px;
    height: 400px;
  }
} */
