.SidebarContainer {
  position: fixed;
  height: 100vh;
  z-index: 1;
  width: 100vw;
  left: -0;
  top: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* margin-top: 100px; */
  padding-top: 100px;
  background-color: #fff;
}
.navLinks {
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  gap: 1rem;
  font-size: 1.2rem;
}
.navLinks > p {
  cursor: pointer;
}
.navLinks > .Touchbt {
  width: 5rem;
}

.showSidebar {
  animation: showSidebar 0.5s ease-out;
  animation-fill-mode: forwards;
}
.hideSidebar {
  animation: hideSidebar 0.5s ease-out;
  animation-fill-mode: forwards;
}
@keyframes showSidebar {
  from {
    left: -130vw;
  }
  to {
    left: 0;
  }
}
@keyframes hideSidebar {
  from {
    left: 0;
  }
  to {
    left: -130vw;
  }
}
