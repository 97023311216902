.videoContainer > video {
  border-radius: 1rem;
  width: 100%;
  cursor: pointer;
}

.videoContainer {
  width: 100%;
  height: 100%;
}
