.Touchbt {
  width: 152px;
  height: 40px;
}
.NavWrapper {
  width: 100%;
  display: flex;
}
.MainDiv {
  width: 100%;
  /* padding-top: 15px; */

  background-color: rgba(240, 248, 254);
  min-height: 100px;
}
.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.MainDiv > .Inter {
  height: 100%;
  margin-right: 40px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.MainDiv > .Inter > .ItemsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 2rem;
  width: 70%;
  min-width: 500px;
}
.MainDiv > .Inter > .ItemsSection > p {
  cursor: pointer;
}
.onmobileonly {
  display: none;
}
.NavBarmbl {
  display: none;
}

.CancelOrOpen {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  padding: 0;
}
.CancelOrOpen:hover {
  color: #696fb2;
  cursor: pointer;
}
.icon {
  animation: show 0.3s ease-out;
  padding: 0;
  font-size: 1.8rem;
}
@keyframes show {
  0% {
    scale: 0.5;
  }
  100% {
    scale: 1;
  }
}

@media (max-width: 950px) {
  .MainDiv > .Inter {
    justify-content: space-between;
  }
  .MainDiv > .Inter > .ItemsSection {
    display: none;
  }
  .onmobileonly {
    display: block;
  }
  .NavBarmbl {
    display: block;
  }
}
