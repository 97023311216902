.Features__Card {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
  /* gap: 1rem; */
  padding-left: 1rem;
  padding-right: 1rem;
}
.Features__Card:hover {
  transform: scale(1.05);
  transition: 0.5s;
  background-color: rgb(179, 217, 230);
}
.Features__Cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.Features__CardTitle {
  font-size: 1.5rem;
  font-weight: 600;
}
.Features__CardDescription {
  font-size: 1rem;
  font-weight: 400;
  color: #6e717d;
}

.feature-icon {
  font-size: 3rem;
  text-align: center;
}

.hover-div {
  position: relative;
  transition: all 0.3s;
}

.hidden-content {
  display: none;
  position: absolute;
  /* top: 100%; */

  /* background-color: rgba(240, 248, 254); */
  padding: 10px;
  width: 250px;
  height: 250px;
  border-radius: 10px;
  transition: all 0.3s;
}

.hover-div:hover .hidden-content {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  transition: all 0.3s;
  background-color: rgba(240, 248, 254);
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.4);
}
