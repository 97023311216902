.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}
.desktop-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: space-between;
}

.desktop-row > img {
  flex: 49%;
  max-width: 49%;

  border-radius: 1rem;
  outline: 1px solid #333;
  margin: 1rem 0;
}

.column img {
  margin-top: 1rem;
  vertical-align: middle;
  width: 100%;
  padding: 0px;
  border-radius: 1rem;
  border: 1px solid #333;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  .desktop-row > img {
    flex: 100%;
    max-width: 100%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
