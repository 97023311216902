.serviceWrapper {
  text-align: center;
  width: 100%;
}
.services {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.servicesContainer {
  display: flex;
  /* gap: 1rem; */
  margin-top: 20px;
  margin-bottom: 20px;
  width: 400px;
  justify-content: space-around;
}
.serimg:hover {
  transform: scale(1.2);
  transition: 0.5s;
}
.servicesTitle:hover {
  color: #3758dd;
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  .services {
    flex-direction: column;
    align-items: center;
  }
  .servicesContainer {
    flex: 0 0 50%;
  }
}
